








import Vue from "vue";

export default Vue.extend({
  name: "Breadcrumbs",
  data() {
    return {
      items: [
        {
          text: this.$t("MENU.HOME"),
          href: "/",
          disabled: false,
        },
      ],
      showBreadcrumbs: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(r) {
        if (r.fullPath === "/") {
          if (this.items.length > 1) {
            this.items.pop();
          }
          this.showBreadcrumbs = false;
        } else {
          this.showBreadcrumbs = true;
          this.items.push({
            text: this.$t(r.name),
            disabled: true,
            href: r.fullPath,
          });
        }
      },
    },
  },
});
