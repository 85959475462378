











import Vue from "vue";

export default Vue.extend({
  name: "Menu",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
