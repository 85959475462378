



import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import feed from "./../assets/data/account/feed.json";

export default Vue.extend({
  name: "Feed",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      feedData: feed,
    };
  },
});
