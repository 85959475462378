



import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import activity from "./../assets/data/account/activity.json";

export default Vue.extend({
  name: "Activity",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      activityData: activity,
    };
  },
});
