














import Vue from "vue";
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Cookies from '@/components/Cookies.vue';

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Footer,
    Breadcrumbs,
    Cookies
  },
  mounted() {
    console.log('version 1.0.0');
  },
});
