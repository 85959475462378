<template>
  <cookie-law
    :message="$t('COOKIES')"
    :buttonText="$t('ACCEPT_AND_CLOSE')"
  ></cookie-law>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  components: { CookieLaw },
};
</script>

<style lang="scss">
.Cookie.Cookie--bottom.Cookie--base {
  background: black;
  color: white;
  .Cookie__button {
    background: #f57c00;
  }
  .Cookie__button:hover {
    background: #d86d00;
  }
}
</style>