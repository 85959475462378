




import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import profile from "./../assets/data/account/profile.json";

export default Vue.extend({
  name: "Profile",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      profileData: profile,
    };
  },
});
