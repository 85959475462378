




















import Vue from "vue";
import menu from "../assets/data/menu.json";
import BasketballPlayer from "../assets/svg/basketball-player.svg";
import BasketballCourt from "../assets/svg/basketball-court.svg";
import BasketballBall from "../assets/svg/basketball-ball.svg";

import Menu from "@/components/Menu.vue";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      menuData: menu,
    };
  },
  components: {
    Menu,
    BasketballPlayer,
    BasketballCourt,
    BasketballBall,
  },
});
