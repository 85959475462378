






























import Vue from "vue";

export default Vue.extend({
  name: "Menu",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
});
