



import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import chat from "./../assets/data/account/chat.json";

export default Vue.extend({
  name: "Notification",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      chatData: chat,
    };
  },
});
