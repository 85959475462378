<template>
    <v-footer padless :color="'#f57c00'" absolute app>
      <v-col class="white--text text-center" cols="12">
        {{ $t('COPYRIGHT', { year: new Date().getFullYear() }) }}
      </v-col>
    </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>