





import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import account from "./../assets/data/account/account.json";

export default Vue.extend({
  name: "Profile",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      accountData: account,
    };
  },
});
