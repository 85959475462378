import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import { defaultLocale, languages } from './assets/i18n/index';

Vue.config.productionTip = false
const messages = Object.assign(languages)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'es',
  messages
})

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
