





import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import join from "./../assets/data/game/join.json";

export default Vue.extend({
  name: "JoinGame",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      joinData: join,
    };
  },
});
