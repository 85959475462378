





import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import map from "./../assets/data/court/map.json";

export default Vue.extend({
  name: "Map",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      mapData: map,
    };
  },
});
