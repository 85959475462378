





import Vue from "vue";
import ExpansionPanels from "@/components/ExpansionPanels.vue";
import registration from "./../assets/data/court/registration.json";

export default Vue.extend({
  name: "RegistrationCourt",
  components: {
    ExpansionPanels,
  },
  data() {
    return {
      registrationData: registration,
    };
  },
});
