import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue';
import Account from '../views/Account.vue';
import Chat from '../views/Chat.vue';
import Notification from '../views/Notification.vue';
import Feed from '../views/Feed.vue';
import Activity from '../views/Activity.vue';
import Map from '../views/Map.vue';
import RegistrationCourt from '../views/RegistrationCourt.vue';
import OrganizedGame from '../views/OrganizedGame.vue';
import JoinGame from '../views/JoinGame.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'MENU.HOME',
    component: Home
  },
  {
    path: '/account',
    name: 'MENU.ACCOUNT.CREATE',
    component: Account
  },
  {
    path: '/profile',
    name: 'MENU.ACCOUNT.PROFILE',
    component: Profile
  },
  {
    path: '/chat',
    name: 'MENU.ACCOUNT.CHAT',
    component: Chat
  },
  {
    path: '/notification',
    name: 'MENU.ACCOUNT.NOTIFICATIONS',
    component: Notification,
  },
  {
    path: '/feed',
    name: 'MENU.ACCOUNT.FEED',
    component: Feed
  },
  {
    path: '/activity',
    name: 'MENU.ACCOUNT.ACTIVITY',
    component: Activity
  },
  {
    path: '/map',
    name: 'MENU.COURT.MAP',
    component: Map
  },
  {
    path: '/registration',
    name: 'MENU.COURT.CREATE',
    component: RegistrationCourt
  },
  {
    path: '/organize',
    name: 'MENU.GAME.ORGANIZE',
    component: OrganizedGame
  },
  {
    path: '/join',
    name: 'MENU.GAME.JOIN',
    component: JoinGame
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
